<template>
  <v-container>
    <p v-if="isError" class="red--text text-h3 my-5 text-center">
      Произошла ошибка! Повторите пройти по ссылке еще раз!
    </p>
    <div v-else-if="isQRInfoLoading" class="text-center my-10">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <h1 class="text-center mb-5">{{ title }}</h1>
      <div class="mx-auto">
        <v-row>
          <v-col cols="12" class="d-flex items-center">
            <p class="text-h4 mb-0">Статус:</p>
            <QRStatus class="ml-3" :status="info.status" />
          </v-col>

          <v-col cols="6" md="3">
            <v-btn
              block
              color="primary"
              :loading="isQrSucessLoading"
              @click="onExecuteYesAction"
              >Да</v-btn
            >
          </v-col>
          <v-col cols="6" md="3">
            <v-btn block @click="onBack">Отменить</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-container>
</template>

<script>
import toast from "@/plugins/toast";

import { QRStatus } from "./ui";
import { USED, EXPIRED, TIME_DOWN, OPENED, TERMINATED } from "./config";
import { QRApi } from "./api";
import { useQrLinkStorage } from "./model";

const { getQrLink, deleteQrLink } = useQrLinkStorage();

export default {
  name: "QRPage",
  components: {
    QRStatus
  },
  data() {
    return {
      qrID: null,
      info: {},
      isError: false,
      isQRInfoLoading: false,
      isQrSucessLoading: false
    };
  },
  computed: {
    title() {
      return {
        [USED]: "Талон уже использован",
        [EXPIRED]: "Время использования талона истекло",
        [TIME_DOWN]: "Время использования талона еще не наступило",
        [OPENED]: "Использовать талон?",
        [TERMINATED]: "Талон отменен"
      }[this.info.status];
    }
  },
  beforeDestroy() {
    deleteQrLink();
  },
  created() {
    if (this.$route.query.q) {
      this.qrID = this.$route.query.q;
      return this.fetchQRInfo();
    } else {
      const qrLink = getQrLink();
      if (qrLink) {
        const qrId = qrLink.split("?")[1]?.split(["="])[1];
        this.qrID = qrId;
        return this.fetchQRInfo();
      }
    }
    this.isError = true;
    toast.error("Произошла ошибка! Повторите пройти по ссылке еще раз!");
  },
  methods: {
    /**
     * API:
     * fetchQRInfo - get
     * setQRAsUsed - post
     *
     * Required query params: q
     *
     * Link:
     * @see http://dev.easybook.kz/#/qr?q=7631
     */
    fetchQRInfo() {
      this.isQRInfoLoading = true;

      QRApi.getQRInfo(this.qrID)
        .then(data => {
          this.info = data;
        })
        .catch(() => {
          this.isError = true;
          toast.error("Произошла ошибка!");
        })
        .finally(() => {
          this.isQRInfoLoading = false;
        });
    },
    onExecuteYesAction() {
      if (this.info.status === OPENED) {
        this.isQrSucessLoading = true;
        QRApi.setQRAsUsed(this.qrID)
          .then(() => {
            this.fetchQRInfo();
            toast.success("Успешное использование талона!");
          })
          .catch(err => {
            const error = new Error("Failed to use talon!");
            error.cause = err;

            const errorCode = err.response?.data?.error_messages[0]?.error_code;
            if (errorCode) {
              const errorMessages = {
                too_early: "талон еще нельзя использовать",
                used: "талон использован",
                terminated: "талон отменен",
                expired: "талон просрочен"
              };
              toast.error(errorMessages[errorCode] || "Произошла ошибка");
            } else {
              toast.error("Произошла ошибка!");
            }

            throw error;
          })
          .finally(() => {
            this.isQrSucessLoading = false;
          });
      } else {
        this.onBack();
      }
    },
    onBack() {
      this.$router.push("/empty");
    }
  }
};
</script>
