import mockQrInfoData from "./mock-qr-info.json";

const axios = window.axios;
const url = "/api/v2/booking/talons";
const isMockData = false;

export const QRApi = {
  async getQRInfo(id) {
    if (isMockData) {
      return Promise.resolve(mockQrInfoData);
    }
    return (await axios.get(`${url}/${id}`)).data;
  },
  async setQRAsUsed(id) {
    if (isMockData) {
      return Promise.resolve();
    }
    return await axios.post(`${url}/use/`, {
      talon: id
    });
  }
};
